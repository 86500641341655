


























import { Component, Ref, Vue } from 'vue-property-decorator';
import { NoteService } from '@/views/customer/note/NoteService';
import { Note } from '@/models/note/Note';
import { ActionResult } from '@/models/ActionResult';

@Component({
    components: {}
})
export default class CustomerNoteForm extends Vue {
    @Ref('noteForm') noteForm!: any;
    listProvince = [];
    isShow = false;
    note = new Note();
    counterDanger = false;
    private _noteService!: NoteService;

    mounted() {
        this._noteService = new NoteService(1);
    }

    onModalShown() {
        this.focusElement();
    }

    onPhoneNumberFocusOut() {
        //console.log('Tìm kiếm khách hàng theo số điện thoại');
    }

    add() {
        this.isShow = true;
    }

    edit(note: Note) {
        this.isShow = true;
        // this.note.id = note.id;
        // this.note.content = note.content;
        // this.noteFormModal.show();
    }

    async save() {
        const result: ActionResult<number> = await this._noteService.insert(this.note);
        if (result.code > 0) {
            // await this.search();
            this.resetModel();
        } else {
            //console.log('Something went wrong');
        }
    }

    cancel() {
        //console.log('cancel clicked');
    }

    private focusElement() {
        const noteInputElement = this.$refs.noteInput as HTMLFormElement;
        noteInputElement.$el.focus();
    }

    private resetModel() {
        this.note.id = null;
        this.note.content = '';
    }
}
