export class Note {
    id?: number | null;
    title?: string | null;
    content: string;
    creatorId?: number;
    creatorFullName?: string;
    createTime?: Date | string;
    concurrencyStamp?: string;
    creatorAvatar?: string;

    constructor() {
        this.content = '';
    }
}
